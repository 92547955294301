<template>
  <div>
    <v-sheet color="red darken-2" height="200" style="width: 100%">
      <v-container>
        <v-layout class="mt-14">
          <div class="white--text">
            <p class="text-h4 font-weight-bold">
              Hi {{ this.$store.state.user.name }},
            </p>
            <p class="text-body-1 font-weight-bold mt-n6 gray">
              Welcome to GRPanel
            </p>
          </div>
        </v-layout>
      </v-container>
    </v-sheet>
    <v-container class="mt-n16">
      <v-card class="mb-3 pb-5" :loading="editing.loading">
        <v-card-text> User editing </v-card-text>
        <div v-if="!editing.isEditing">
          <v-text-field
            v-model="editing.username"
            label="Username"
            outlined
            class="mx-4"
            v-if="!editing.isEditing"
            hide-details="auto"
            required
          ></v-text-field>

          <v-row class="mx-4 mt-3">
            <v-btn
              text
              class="ml-auto"
              @click="getData"
              :disabled="editing.loading"
              color="info"
            >
              Check
            </v-btn>
          </v-row>
        </div>
        <div class="mt-n5" v-if="editing.isEditing">
          <v-layout>
            <v-avatar class="my-auto ml-4 mr-n2" size="25">
              <v-img :src="editing.user.pfp"></v-img>
            </v-avatar>
            <div>
              <v-card-text class="grey--text"
                >@{{ editing.user.username }}</v-card-text
              >
            </div></v-layout
          >
          <v-text-field
            v-model="editing.data.gp"
            label="GP"
            @input="(s) => (editing.data.gp = parseInt(s))"
            type="number"
            outlined
            class="mx-4"
            hide-details="auto"
            required
          ></v-text-field>
          <v-card
            class="mx-4 mt-3"
            v-for="data in editing.history"
            :key="data.id"
            outlined
          >
            <v-card-title>
              {{ new Date(data.created).toLocaleDateString() }}
            </v-card-title>
            <v-card-text class="mt-n4"> {{ data.data }} GP </v-card-text>
          </v-card>
          <vue-json-editor
            v-model="editing.data"
            :show-btns="false"
            class="mx-4 mt-2"
            v-if="editing.isDeveloper"
            :expandedOnStart="true"
          ></vue-json-editor>
          <v-row class="mx-4 mt-3">
            <v-btn
              text
              class=""
              @click="editing.isEditing = false"
              :disabled="editing.loading"
              color="success"
            >
              Change user
            </v-btn>
            <v-btn
              text
              class="ml-auto"
              @click="saveData"
              :disabled="editing.loading"
              color="info"
            >
              Save
            </v-btn></v-row
          >
        </div>
      </v-card>
      <div class="mt-6">
        <v-card v-for="server of servers" :key="server.id" class="py-2 mb-2" outlined>
          <v-row>
            <div v-if="server.status === 'offline'" class="ring-container my-auto ml-6 mr-0">
              <div class="ringring-red"></div>
              <div class="circle-red"></div>
            </div>
            <div v-if="server.status === 'online'" class="ring-container my-auto ml-6 mr-0">
              <div class="ringring"></div>
              <div class="circle"></div>
            </div>
            <div class="ml-0">
              <v-card-title> {{ server.id }} </v-card-title>
              <v-card-text class="mt-n6 red--text font-weight-black">
                {{ server.status === 'offline' ? 'Offline' : 'Online'}} for {{ server.status === 'Offline' ? server.minsSincePing : server.minsSinceFirstPing }} minutes
              </v-card-text>
            </div>
            <div class="my-auto ml-auto mr-6"> 
              <v-btn  text color="info" @click="$router.push(`/server/${server.id}`)">
              View info
             </v-btn>
             <v-btn  text color="error">
              Delete
             </v-btn>
            </div>
          </v-row>
        </v-card>
      </div>
    </v-container>
    <v-snackbar v-model="toast.open">
      {{ toast.message }}

      <template v-slot:action="{ attrs }">
        <v-btn
          :color="toast.color"
          text
          v-bind="attrs"
          timeout="2000"
          @click="toast.open = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import vueJsonEditor from "vue-json-editor";
import pusher from 'pusher-js'


export default {
  name: "Home",

  components: { vueJsonEditor },
  data: () => ({
    loading: false,
    servers: [],
    editing: {
      isEditing: false,
      loading: false,
      username: "",
      data: {
        gp: 72000,
        rep: "",
        rawdata: {},
        history: [],
      },
      user: {
        username: "ItsWHOOOP",
        pfp: "",
      },
    },

    toast: {
      message: "",
      color: "success",
      open: false,
    },
  }), created() {
    this.$http.get(`/game/servers`).then((res) => {
      this.servers = res.data.servers.map(server => {
        const lastPing = server.ping[server.ping.length - 1];
        const firstPing = server.ping[0];
        console.log(lastPing);
        const minsSincePing = Math.floor((Date.now() - new Date(lastPing.timestamp)) / 1000 / 60);
        const minsSinceFirstPing = Math.floor((Date.now() - new Date(firstPing.timestamp)) / 1000 / 60);
        return {
          ...server,
          minsSincePing,
          minsSinceFirstPing,
        }
      });
    });

    setInterval(() => {
      console.log('supdating');
      const newServers = this.servers.map(server => {
        const lastPing = server.ping[server.ping.length - 1];
        const firstPing = server.ping[0];
        const minsSincePing = Math.floor((Date.now() - new Date(lastPing.timestamp)) / 1000 / 60);
        const minsSinceFirstPing = Math.floor((Date.now() - new Date(firstPing.timestamp)) / 1000 / 60);
        return {
          ...server,
          minsSincePing,
          minsSinceFirstPing,
        }
      });
      this.servers = newServers;
    }, 1000 * 30);

    pusher.logToConsole = true;
    const pclient = new pusher('657f8c412185aa5cb845', {
      cluster: 'us3'
    });

    const channel = pclient.subscribe('servers');
    channel.bind('update', (data) => {
      const servers = data.servers.map(server => {
        const lastPing = server.ping[server.ping.length - 1];
        const firstPing = server.ping[0];
        const minsSincePing = Math.floor((Date.now() - new Date(lastPing.timestamp)) / 1000 / 60);
        const minsSinceFirstPing = Math.floor((Date.now() - new Date(firstPing.timestamp)) / 1000 / 60);
        return {
          ...server,
          minsSincePing,
          minsSinceFirstPing,
        }
      });
      this.servers = servers;

    })
  },
  methods: {
    getData() {
      this.editing.loading = true;
      this.$http.get(`/gp/${this.editing.username}`).then((res) => {
        this.editing.data = res.data.data;
        this.editing.isDeveloper = res.data?.isDeveloper;
        this.editing.history = res.data.versions;

        this.editing.user = res.data.user;
        this.editing.isEditing = true;
        this.editing.loading = false;
      });
    },
    async saveData() {
      this.editing.loading = true;
      if (this.isDeveloper) {
        this.editing.data.gp = this.editing.gp;
      }
      console.log(this.editing.data);
      await this.$http.post(
        `/gp/${this.editing.user.username}`,
        this.editing.data
      );
      this.editing.loading = false;
      this.toast.message = "Saved!";
      this.toast.color = "success";
      this.toast.open = true;
    },
  },
};
</script>

<style>
.ring-container {
  position: relative;
  width: 25px;
  height: 25px;
  margin: 0 auto;
}

.circle {
  width: 15px;
  height: 15px;
  background-color: #62bd19;
  border-radius: 50%;
  top: 5px;
  left: 5px;
  position: absolute;
}

.ringring {
  border: 3px solid #62bd19;
  -webkit-border-radius: 30px;
  height: 25px;
  width: 25px;
  position: absolute;
  -webkit-animation: pulsate 1s ease-out;
  -webkit-animation-iteration-count: infinite;
  opacity: 0;
}

.circle-red {
  width: 15px;
  height: 15px;
  background-color: #fa0202;
  border-radius: 50%;
  top: 5px;
  left: 5px;
  position: absolute;
}

.ringring-red {
  border: 3px solid #fa0202;
  -webkit-border-radius: 30px;
  height: 25px;
  width: 25px;
  position: absolute;
  -webkit-animation: pulsate 1s ease-out;
  -webkit-animation-iteration-count: infinite;
  opacity: 0;
}
@-webkit-keyframes pulsate {
  0% {
    -webkit-transform: scale(0.1, 0.1);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1.2, 1.2);
    opacity: 0;
  }
}
</style>
