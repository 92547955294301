import Vue from 'vue'
import VueRouter from 'vue-router'
import home from '@/views/home.vue'
import signin from '@/views/signin'
import lookup from '@/views/lookup'
import groupBans from '@/views/groupBans'
import ticket from '@/views/ticket'
import reports from '@/views/reports'
import ban from '@/views/ban'
import images from '@/views/images'
import banhistory from '@/views/banhistory'
import disabled from '@/views/disabled'
import err from '@/views/err'
import tickets from '@/views/tickets'
import profile from '@/views/profile'
import server from '@/views/server'
import areports from '@/views/areports'
import gban from '@/views/gban'
import developer from '@/views/developer'
import choosen1 from '@/views/choosen1'
import admin from '@/views/admin'
import game from '@/views/game'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: home,
    meta: {
      layout: 'main'
    }
  },
  {
    path: '/developer',
    name: 'Developer',
    component: developer,
    meta: {
      layout: 'main'
    }
  },
  {
    path: '/banhistory',
    name: 'Banhistory',
    component: banhistory,
    meta: {
      layout: 'main'
    }
  },
  {
    path: '/server/:id',
    name: 'Server',
    component: server,
    meta: {
      layout: 'main'
    }
  },

  {
    path: '/ticket/:id',
    name: 'Ticket',
    component: ticket,
    meta: {
      layout: 'main'
    }
  },
  {
    path: '/tickets',
    name: 'Tickets',
    component: tickets,
    meta: {
      layout: 'main'
    }
  },
  {
    path: '/choosen1',
    name: 'Choosen1',
    component: choosen1,
    meta: {
      layout: 'default',
    }
  },
  {
    path: '/game',
    name: 'Home',
    component: game,
    meta: {
      layout: 'main'
    }
  }, {
    path: '/admin',
    name: 'Admin',
    component: admin,
    meta: {
      layout: 'main'
    }
  }, {
    path: '/profile/:id',
    name: 'Profile',
    component: profile,
    meta: {
      layout: 'main'
    }
  }, {
    path: '/error',
    name: 'Server error',
    component: err,
    meta: {
      layout: 'default'
    }
  }, {
    path: '/disabled',
    name: 'Panel disabled',
    component: disabled,
    meta: {
      layout: 'default'
    }
  }, {
    path: '/reports',
    name: 'Reports',
    component: reports,
    meta: {
      layout: 'main'
    }
  }, {
    path: '/areports',
    name: 'aReports',
    component: areports,
    meta: {
      layout: 'main'
    }
  }, {
    path: '/images',
    name: 'Images',
    component: images,
    meta: {
      layout: 'main'
    }
  }, {
    path: '/ban',
    name: 'Ban',
    component: ban,
    meta: {
      layout: 'main'
    }
  }, {
    path: '/gban',
    name: 'Group bans',
    component: gban,
    meta: {
      layout: 'main'
    }
  },
  
  {
    path: '/groupbans',
    name: 'Group bans list',
    component: groupBans,
    meta: {
      layout: 'main'
    }
  }, {
    path: '/lookup',
    name: 'Lookup',
    component: lookup,
    meta: {
      layout: 'main'
    }
  }, {
    path: '/signin',
    name: 'Signin',
    component: signin,
    meta: {
      layout: 'default'
    }
  }, {
    path: '/*',
    name: 'Redirecy',
    redirect: '/'
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
