<template>
  <div>
    <v-container>
      <v-layout v-if="!loading" class="mt-12">
        <v-avatar class="my-auto ml-3 mr-3" color="red darken-2" size="80">
          <v-img :src="data.pfp"></v-img>
        </v-avatar>
        <div class="my-auto">
          <p class="text-h4 my-auto font-weight-bold">{{ data.displayName }}</p>
          <p
            v-if="data.ruser != data.displayName"
            class="text-body-1 my-auto grey--text mt-n1 gray"
          >
            @{{ data.username }}
          </p>
        </div></v-layout
      >
      <v-tabs class="mt-7 mb-n3" v-model="tab" color="red darken-2">
        <v-tab href="#tab-1">Stats</v-tab>
      </v-tabs>
    </v-container>
    <v-divider class="mb-n2"></v-divider>
    <v-container class="mt-2">
      <v-card v-if="loading" outlined>
        <v-row class="mx-auto mb-5 mt-5">
          <v-progress-circular
            :size="50"
            color="amber"
            class="mx-auto"
            indeterminate
          ></v-progress-circular>
          <v-card-text class="text-center mt-2 mt-n2 mb-n5">
            Loading...
          </v-card-text>
        </v-row></v-card
      >
      <v-tabs-items v-model="tab" v-if="!loading">
        <v-tab-item :key="1" :value="'tab-' + 1">
          <v-select
            v-model="selectedPreset"
            :items="options"
            outlined
            @change="updateDates()"
            label="Time"
            class="mt-2 mb-n7"
          >
          </v-select>
          <v-btn
            class="ml-auto mb-2 mt-1 elevation-0"
            @click="customOpen = true"
            color="info"
          > Custom dates</v-btn>
          <v-row class="">
            <v-col order="last">
              <v-card outlined class="">
                <v-card-text class=""> Bans made </v-card-text>

                <p class="ml-3 mb-2 mt-5 text-h2 mt-n5">
                  {{ bans }}
                </p>
              </v-card>
            </v-col>
            <v-col order="last">
              <v-card outlined class="">
                <v-card-text class=""> Tickets </v-card-text>
                <p class="ml-3 mb-2 mt-5 text-h2 mt-n5">
                  {{ tickets }}
                </p>
              </v-card>
            </v-col>
            <v-col order="last">
              <v-card outlined class="">
                <v-card-text class=""> Warnings </v-card-text>
                <p class="ml-3 mb-2 mt-5 text-h2 mt-n5">
                  {{ warnings }}
                </p>
              </v-card>
            </v-col>
            <v-col order="last">
              <v-card outlined class="">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-progress-linear
                      background-color="red"
                      v-bind="attrs"
                      v-on="on"
                      color="green"
                      class="mb-n1"
                      :value="reportsStatusPrecentage"
                    ></v-progress-linear>
                  </template>
                  <span
                    >Accepted: {{ acceptedReports }}, Denied:
                    {{ deniedReports }}</span
                  >
                </v-tooltip>
                <v-card-text class=""> Reports </v-card-text>
                <p class="ml-3 mb-2 mt-5 text-h2 mt-n5">
                  {{ reports.length }}
                </p>
              </v-card>
            </v-col>
          </v-row>
          <v-card outlined class="mt-3">
            <v-card-text class=""> Time spent in-game </v-card-text>
            <p class="ml-3 mb-2 mt-5 text-h2 mt-n5">{{ mins }}</p>
          </v-card>
        </v-tab-item>
        <v-tab-item :key="2" :value="'tab-' + 2"> NO </v-tab-item>
      </v-tabs-items>
    </v-container>
    <v-overlay v-if="customOpen">
      <v-dialog v-model="customOpen" max-width="400px" max-height="400px">
        <v-card class="mx-auto">
          <v-card-title class="pb-4"> </v-card-title>
          <div class="mx-6">
            
            <v-date-picker
              class="mb-3"
              dark
              range
              :max="new Date().toISOString().split('T')[0]"
              v-model="time"
              full-width
              color="blue"
            ></v-date-picker>
          </div>
          <v-divider></v-divider>
          <v-layout class="px-2 py-2">
            <v-btn @click="reloadData(true)" text color="success"> Update </v-btn>
            <v-btn text @click="customOpen = false" color="error">
              Cancel
            </v-btn></v-layout
          >
        </v-card>
      </v-dialog>
    </v-overlay>
  </div>
</template>
<script>
export default {
  name: "profile",
  data: () => ({
    loading: true,
    tab: 1,
    customOpen: false,
    time: [],
    mins: 0,
    options: ["All time", "Month", "Last month", "Custom"],
    selectedPreset: "Month",
    data: {
      pfp: "",
      info: {
        displayName: "",
        username: "",
      },
    },
    bans: [],
    tickets: [],
    reports: [],
  }),
  computed: {
    reportsStatusPrecentage() {
      const reports = this.reports.filter((report) => report.status !== "none");
      let open = reports.filter(
        (report) => report.status === "accepted"
      ).length;
      //let denied = reports.filter((report) => report.status === "denied").length;
      let total = reports.length;
      let openPrecentage = Math.round((open / total) * 100);
      console.log(reports);
      return openPrecentage;
    },
    acceptedReports() {
      const reports = this.reports.filter((report) => report.status !== "none");
      let open = reports.filter(
        (report) => report.status === "accepted"
      ).length;
      return open;
    },
    deniedReports() {
      const reports = this.reports.filter((report) => report.status !== "none");
      let denied = reports.filter(
        (report) => report.status === "denied"
      ).length;
      return denied;
    },
  }, 
  async created() {
    const todaysDate = new Date();
    todaysDate.setDate(0);
    todaysDate.setHours(0);
    todaysDate.setMinutes(0);

    this.time = [
      new Date(todaysDate).toISOString().split("T")[0],
      new Date().toISOString().split("T")[0],
    ]
    console.log(this.time)

    let req = await this.$http.get(`/user/${this.$route.params.id}`, {
      params: {
        min: todaysDate,
      },
    });
    console.log(req.data);
    this.data = req.data;
    this.bans = req.data.bans.length;
    this.tickets = req.data.tickets.length;
    this.warnings = req.data.warnings.length;
    this.reports = req.data.reports;
    this.mins = req.data.mins;

    this.loading = false;
  },
  methods: {
    updateDates() {
      const kind = this.selectedPreset;
      if (kind === 'Custom') return
      if (kind === 'Month') {
        const todaysDate = new Date();
        todaysDate.setDate(0);
        todaysDate.setHours(0);
        todaysDate.setMinutes(0);

        this.time = [
          new Date(todaysDate).toISOString().split("T")[0],
          new Date().toISOString().split("T")[0],
        ]
        this.reloadData(false);
      } else if (kind === 'Last month') {
        const todaysDate = new Date();
        todaysDate.setDate(0);
        todaysDate.setHours(0);
        todaysDate.setMinutes(0);

        const lastMonth = new Date();
        lastMonth.setDate(0);
        lastMonth.setHours(0);
        lastMonth.setMinutes(0);
        lastMonth.setMonth(lastMonth.getMonth() - 1);

        this.time = [
          new Date(lastMonth).toISOString().split("T")[0],
          new Date(todaysDate).toISOString().split("T")[0],
        ]
        this.reloadData(false);
      } else if (kind === 'All time') {
        this.time = [];
        this.reloadData(false);
      }
    },
    reloadData(fromPopup) {
      this.loading = true;
      const sortedTimes = this.time.sort((a, b) => a - b);
      const min = sortedTimes[0];
      const max = sortedTimes[1];
      if (fromPopup) {
        this.customOpen = false;
        this.selectedPreset = "Custom";
      }

      this.$http
        .get(`/user/${this.$route.params.id}`, {
          params: {
            min,
            max,
          },
        })
        .then((res) => {
          this.data = res.data;
          this.bans = res.data.bans.length;
          this.tickets = res.data.tickets.length;
          this.warnings = res.data.warnings.length;
          this.reports = res.data.reports;
          this.mins = res.data.mins;
          this.loading = false;
        });
    }
  },
};
</script>
