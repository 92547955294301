<template>
  <div>
    <v-sheet color="red darken-2" height="200" style="width: 100%">
      <v-container>
        <v-layout class="mt-14">
          <div class="white--text">
            <p class="text-h4 font-weight-bold">Hi {{ this.$store.state.user.name }},</p>
            <p class="text-body-1 font-weight-bold mt-n6 gray">Welcome to GRPanel</p>
          </div>
        </v-layout>
      </v-container>
    </v-sheet>
    <v-container class="mt-n16">
      <!-- <v-row v-if="$store.state.user.permission >= 4">
        <v-col>
          <v-card outlined>
            <v-card-text> Bans this week </v-card-text>
            <p class="text-h1 ml-4 mt-n2">71</p></v-card
          ></v-col
        >
        <v-col>
          <v-card outlined>
            <v-card-text> Reports this week </v-card-text>
            <p class="text-h1 ml-4 mt-n2">9</p></v-card
          ></v-col
        >
      </v-row> -->
      <v-row class="mt-n2">
        <v-col order="last" cols="12" sm="4">
          <v-card to="/reports" outlined v-ripple>
            <v-layout>
              <div>
                <v-card-title> 📋 Reports </v-card-title>
                <v-card-subtitle style="max-width: 1500px" class="mt-n6 ctext grey--text">
                  View your reports
                </v-card-subtitle>
              </div>
              <v-spacer></v-spacer>
            </v-layout>
          </v-card>
        </v-col>
        <v-col order="last" cols="12" sm="4">
          <v-card to="/lookup" outlined v-ripple>
            <v-layout>
              <div>
                <v-card-title> 🔎 Lookup </v-card-title>
                <v-card-subtitle style="max-width: 1500px" class="mt-n6 ctext grey--text">
                  Lookup a users ban history
                </v-card-subtitle>
              </div>
              <v-spacer></v-spacer>
            </v-layout>
          </v-card>
        </v-col>
        <v-col order="last" cols="12" sm="4">
          <v-card outlined to="/images" v-ripple>
            <v-layout>
              <div>
                <v-card-title> 🎆 Images </v-card-title>
                <v-card-subtitle style="max-width: 1500px" class="mt-n6 ctext grey--text">
                  Click to view your GRP images
                </v-card-subtitle>
              </div>
              <v-spacer></v-spacer>
            </v-layout>
          </v-card>
        </v-col>
      </v-row>
      <v-card outlined v-if="this.$store.state.user.verified" class="mt-3">
        <div>
          <v-card-title> Linked to the Discord account </v-card-title>
          <v-layout class="mt-n3 mb-3 w-full">
            <v-avatar class="my-auto ml-3 mr-3" color="red darken-2" size="50">
              <v-img :src="this.$store.state.user.discord.pfp"></v-img>
            </v-avatar>
            <div class="my-auto">
              <p class="text-body-0 my-auto grey--text mt-n1 gray">
                @{{ this.$store.state.user.discord.username }}
              </p>
            </div>
            <v-spacer></v-spacer>
            <v-btn color="red darken-2" class=" my-auto ml-auto mr-3" :loading="sloading" plain @click="() => unlink()">Unlink</v-btn>
          </v-layout>
        </div>
      </v-card>
      <v-card outlined v-if="!this.$store.state.user.verified" class="mt-3">
        <div>
          <v-layout class=" w-full">
            <div class="my-auto">
              <v-card-title> Not linked to a Discord account </v-card-title>
            </div>
            <v-spacer></v-spacer>
            <v-btn color="red darken-2" class=" my-auto ml-auto mr-3" plain @click="() => link()">Link account</v-btn>
          </v-layout>
        </div>
      </v-card>
      <v-card class="mt-3" outlined>
        <v-row class="mx-auto mb-5 mt-5">
          <v-img src="../assets/waiting-6.png" class="mx-auto mt-7" max-width="400" v-if="clicks < 5" ripple
            style="cursor: pointer" @click="clicks++"></v-img>
          <v-img src="https://freepngimg.com/thumb/ariana_grande/21486-4-ariana-grande-transparent.png"
            class="mx-auto mt-7 mb-3" v-if="clicks === 5" max-width="400"></v-img>
          <v-card-text class="text-center mt-n3 mb-n4">
            {{ clicks === 5 ? "Ariana Grande graphic here" : "There is nothing here so heres a graphic instead" }}
          </v-card-text>
        </v-row></v-card>
    </v-container>
    <v-snackbar v-model="toast.open">
      {{ toast.message }}

      <template v-slot:action="{ attrs }">
        <v-btn
          :color="toast.color"
          text
          v-bind="attrs"
          timeout="2000"
          @click="toast.open = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  name: "Home",
  data: () => ({
    sloading: false,
    clicks: 0,
    toast: {
      message: "",
      color: "success",
      open: false,
    },
  }),
  methods: {
    async unlink() {
      this.sloading = true;
      console.log("unlinking");
      try {
        await this.$http.post(`/unverify`);
        this.toast.message = "Unverified";
        this.toast.color = "success";
        this.toast.open = true;
        this.$store.commit("setUser", {
          ...this.$store.state.user,
          verified: false,
        });
      } catch (e) {
        return;
      }

      this.sloading = false;
    },
    async link() {
      this.sloading = true;
      window.location = `${this.$http.defaults.baseURL}/login/discord`;
      this.sloading = false;
    },
  },
  components: {},
};
</script>
