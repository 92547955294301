import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: {
      name: '',
      pfp: '',
      dark: true,
      otherInfo: Object,
      id: 1,
      isDeveloper: false,
      verified: false,
      chosenOne: false,
      discord: {
        username: '',
        pfp: '',
        id: '',
      }
    }, 
    disabled: ''
  },
  mutations: {
    setUser(state, user) {
      state.user = user
    },
    setDisabled(state, disabled) {
      state.disabled = disabled
    },
    noLongerChosenOne(state) {
      state.user.chosenOne = false
    },
    unverify(state) {
      state.user.verified = false
    }
  },
  actions: {

  },
  modules: {

  }
})
