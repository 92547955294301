<template>
    <div>
      <v-sheet color="red darken-2" height="200" style="width: 100%">
        <v-container>
          <v-layout class="mt-14">
            <div class="white--text">
              <p class="text-h4 font-weight-bold">Hi {{ this.$store.state.user.name }},</p>
              <p class="text-body-1 font-weight-bold mt-n6 gray">Welcome to GRPanel</p>
            </div>
          </v-layout>
        </v-container>
      </v-sheet>
      <v-container class="mt-n16">
        <v-row>
          <v-col>
            <v-card :loading="sloading" outlined>
              <v-card-text> Search </v-card-text>
              <v-form @submit.prevent="searchs" ref="form">
                <v-text-field
                  :disabled="sloading"
                  v-model="search.text"
                  filled
                  @input="search.found = true"
                  class="mx-4"
                  label="GroupID"
                >
                </v-text-field>
              </v-form>
              </v-card
            ></v-col
          >
        </v-row>
        <div v-for="(ban) in filteredResults" :key="ban.banid">
          <v-card outlined class="mt-2">
            <div v-if="ban.proof" class="rounded">
              <v-img
                v-for="(proof, i) of images(ban)"
                style="cursor: pointer"
                class="rounded"
                :key="i"
                :src="proof"
                v-ripple
                outlined
                aspect-ratio="1"
                tile
                max-height="80px"
                @click="open(proof)"
              >
              </v-img>
            </div>
            <v-card-title> #{{ban.targetid}} </v-card-title>
            <v-card-subtitle class="mt-n6"> {{ ban.reason }}</v-card-subtitle>
              <v-card-subtitle
                v-if="!ban.enabled"
                class="mt-n9 mb-1 red--text font-weight-bold"
              >
                Inactive
              </v-card-subtitle>
              <v-card-subtitle
                v-if="ban.enabled"
                class="mt-n9 mb-1 green--text font-weight-bold"
              >
                Active
              </v-card-subtitle>
           
            <v-btn
              color="info"
              v-if="$store.state.user.permission >= 4"
              :disabled="!ban.enabled"
              class="elevation-0 ml-4 mt-n2 mb-3"
              @click="ope(ban)"
            >
              Edit ban
            </v-btn>
            <div v-if="ban.proof">
              <v-card
                v-ripple
                @click="openpage(proof)"
                class="mx-4 mb-2"
                v-for="(proof, i) of ban.proof.split(' ')"
                :key="i"
                outlined
                ><v-card-title> {{ proof }} </v-card-title>
              </v-card>
            </div>
            <v-card outlined class="mx-4 mb-3">
              <v-card-title> Ban info </v-card-title>
              <v-layout wrap>
                <div class="ml-4 mt-n4">
                  <h3>Ban reason</h3>
                  <p>{{ ban.reason }}</p>
                </div>
                <div class="ml-4 mt-n4">
                  <h3>Created</h3>
                  <p>{{ gettime(ban.creationdate) }}</p>
                </div>
                <div class="ml-4 mt-n4">
                  <h3>Expires</h3>
                  <p>{{ gettime(ban.expires) }}</p>
                </div>
                
                <div class="ml-4 mt-n4">
                  <h3>User ID</h3>
                  <p>{{ ban.targetid }}</p>
                </div>
                <div class="ml-4 mt-n4">
                  <h3>Ban ID</h3>
                  <p>{{ ban.banid }}</p>
                </div>
              </v-layout>
            </v-card></v-card
          >
        </div>
      </v-container>
      <v-container v-if="img.open" flex class="mx-auto" style="width: 100%">
        <v-overlay>
          <v-dialog v-model="img.open" max-width="1000px" max-height="1000px">
            <v-card class="mx-auto">
              <v-img :src="img.src">
                <v-card-actions>
                  <v-spacer></v-spacer
                  ><v-btn color="red" bottom plain @click="img.open = false"> Close </v-btn>
                </v-card-actions></v-img
              >
            </v-card>
          </v-dialog>
        </v-overlay>
      </v-container>
  
      <v-container v-if="ban.visible" flex class="mx-auto" style="width: 100%">
        <v-overlay>
          <v-dialog v-model="ban.visible" max-width="400px" max-height="400px">
            <v-card class="mx-auto">
              <v-card-title class="pb-4">Ban #{{ ban.data.banid }} </v-card-title>
              <div class="mx-6">
                <v-text-field
                  filled
                  hide-details="auto"
                  label="Proof"
                  v-model="ban.data.proof"
                >
                </v-text-field>
                <v-textarea
                  rows="3"
                  class="rounded-0"
                  filled
                  label="Reason"
                  v-model="ban.data.reason"
                />
                {{ ban.newdate }}
                <v-row>
                  <v-switch
                    class="mx-auto mt-n1 mb-2"
                    label="Permanent"
                    v-model="ban.date.perm"
                /></v-row>
                <v-date-picker
                  class="mb-3"
                  dark
                  v-if="!ban.date.perm"
                  full-width
                  color="blue"
                  v-model="ban.date.new"
                ></v-date-picker>
              </div>
              <v-divider></v-divider>
              <v-layout class="px-2 py-2">
                <v-btn @click="confirmban" text color="success"> Update </v-btn>
                <v-btn text @click="ban.visible = false" color="error">
                  Cancel
                </v-btn></v-layout
              >
            </v-card>
          </v-dialog>
        </v-overlay>
      </v-container>
      <v-snackbar v-model="toast.open">
        {{ toast.message }}
  
        <template v-slot:action="{ attrs }">
          <v-btn
            :color="toast.color"
            text
            v-bind="attrs"
            timeout="2000"
            @click="toast.open = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </div>
  </template>
  
  <script>
  export default {
    name: "Home",
  
    components: {},
    data: () => ({
      search: {
        found: false,
        text: "",
      },
      toast: {
        message: "",
        color: "success",
        open: false,
      },
      ban: {
        visible: false,
        data: {},
        date: {
          new: "",
          perm: false,
        },
      },
      img: {
        open: null,
        src: null,
      },
      results: {},
  
      sloading: false,
    }),
    computed: {
      filteredResults() {
        if (this.search.text === "") {
          return this.results;
        } else {
          return this.results.filter((result) => {
            return String(result.targetid).includes(this.search.text);
              
          });
        }
      }
    },
    mounted() {
      this.$http.get(`/gban/list`).then((res) => {
        this.results = res.data.bans;
        this.search.found = true;
      });

    },
    methods: {
      ope(data) {
        let current = new Date(data.expires);
        this.ban.date.new = `${current.getFullYear()}-${(current.getMonth() + 1)
          .toString()
          .padStart(2, "0")}-${current.getDate().toString().padStart(2, "0")}`;
        if (current.getTime() === 0) {
          this.ban.date.perm = true;
          this.ban.date.new = this.getcur();
        }
        this.ban.data = data;
        this.ban.visible = true;
      },
      async confirmban() {
        let date = new Date(this.ban.date.perm ? 0 : this.ban.date.new);
        console.log(date);
        this.ban.visible = false;
        try {
          await this.$http.patch("/ban/" + this.ban.data.banid, {
            proof: this.ban.data.proof,
            reason: this.ban.data.reason,
            expires: date.getTime(),
          });
        } catch (e) {
          this.toast.open = true;
          this.toast.message = "Error updating ban";
          return;
        }
        this.toast.open = true;
        this.toast.message = "Ban Updated";
        let result = this.results.find((x) => x.banid === this.ban.data.banid);
        result.expires = date;
      },
      images(ban) {
        return ban.proof.split(" ").filter((i) => this.$isimage(i));
      },
      open: function (src) {
        this.img.src = src;
        this.img.open = true;
      },
      gettime: function (date) {
        let datex = new Date(date);
        if (datex.getTime() === 0) return "Never";
        return datex.toLocaleString("en-US", { timeZone: "America/New_York" });
      },
      getcur: function () {
        let current = new Date();
        return `${current.getFullYear()}-${(current.getMonth() + 1)
          .toString()
          .padStart(2, "0")}-${current.getDate()}`;
      },
      openpage: function (url) {
        window.open(url);
      },
    },
  };
  </script>
  