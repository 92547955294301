<template>
  <div>
    <v-sheet color="red darken-2" height="200" style="width: 100%">
      <v-container>
        <v-layout class="mt-14">
          <div class="white--text">
            <p class="text-h4 font-weight-bold">Hi {{ this.$store.state.user.name }},</p>
            <p class="text-body-1 font-weight-bold mt-n6 gray">Welcome to GRPanel3</p>
          </div>
        </v-layout>
      </v-container>
    </v-sheet>
    <v-container class="mt-n16">
      <!-- <v-card class="mb-3" :loading="loading" outlined>
        <v-card-text> Search reports </v-card-text>
        <v-form class="mx-4 mb-10" ref="form">
          <v-text-field filled hide-details="auto" label="Username" />
        </v-form>
      </v-card> -->
      <div v-for="(report, i) in reports" :key="i">
        <v-card v-if="report.length != 1" outlined class="mb-2">
          <div>
            <v-card-title> {{ report[0].username }} </v-card-title>
          </div>
          <v-card v-for="(r, i) in report" :key="i" outlined class="mx-3 mb-2">
            <div class="rounded" v-if="!report.nsfw">
              <v-img
                style="cursor: pointer"
                class="rounded-t"
                v-ripple
                outlined
                v-for="(img, i) in images(r)"
                :key="i"
                aspect-ratio="1"
                tile
                :src="img"
                max-height="80px"
                @click="open(img)"
              >
              </v-img>
            </div>
            <div>
              <v-card-subtitle class="mt-0 mb-n8 blue--text font-weight-bold">
                Reported by {{ r.reporterr }}
              </v-card-subtitle>
              <v-card-subtitle
                v-if="r.nsfw"
                class="mt-n5 mb-n8 red--text font-weight-bold"
              >
                NSFW
              </v-card-subtitle>

              <v-card-subtitle class="mt-n5"> {{ r.report }} </v-card-subtitle>
            </div>
            <v-expand-transition>
              <div class="pb-2">
                <v-card
                  v-for="(link, i) in r.proof.split(' ')"
                  :key="i"
                  outlined
                  class="mx-3 mb-3"
                  ripple
                  @click="openpage(link)"
                >
                  <v-card-title class="my-n2"> {{ link }}</v-card-title></v-card
                >
              </div>
            </v-expand-transition></v-card
          >
          <v-expand-transition>
            <div class="mx-3">
              <v-text-field
                filled
                hide-details="auto"
                label="Username"
                v-model="report[0].username"
              >
              </v-text-field>
              <v-text-field
                filled
                class="rounded-0"
                hide-details="auto"
                label="Proof"
                v-model="report[0].proof"
              >
              </v-text-field>
              <v-text-field
                filled
                class="rounded-0"
                hide-details="auto"
                label="Note to reporter"
                v-model="report[0].note"
              >
              </v-text-field>
              <v-text-field
                filled
                class="rounded-0"
                hide-details="auto"
                label="Time"
                v-model="report[0].time"
              >
              </v-text-field>
              <v-textarea
                rows="3"
                class="rounded-0"
                filled
                label="Reason"
                v-model="report[0].report"
              >
              </v-textarea>
            </div>
          </v-expand-transition>
          <v-divider> </v-divider>
          <v-card-actions class="py-2">
            <v-btn text @click="denb(report)" color="red"> Deny </v-btn>
            <v-btn text @click="accnb(report)" color="green"> Accept (no ban)</v-btn>
            <v-btn text @click="accw(report)" color="green"> Accept (warn) </v-btn>

            <v-btn text @click="accb(report)" color="green"> Ban </v-btn>
          </v-card-actions>
        </v-card>
        <v-card v-if="report.length == 1" outlined class="mb-2">
          <div class="rounded" v-if="!report[0].nsfw">
            <v-img
              style="cursor: pointer"
              class="rounded-t"
              v-ripple
              outlined
              v-for="(img, i) in images(report[0])"
              :key="i"
              aspect-ratio="1"
              tile
              :src="img"
              max-height="80px"
              @click="open(img)"
            >
            </v-img>
          </div>
          <div>
            <v-card-title> {{ report[0].username }} </v-card-title>
            <v-card-subtitle class="mt-n5 mb-n8 blue--text font-weight-bold">
              Reported by {{ report[0].reporterr }}
            </v-card-subtitle>
            <v-card-subtitle
              v-if="report[0].nsfw"
              class="mt-n5 mb-n8 red--text font-weight-bold"
            >
              NSFW
            </v-card-subtitle>

            <v-card-subtitle class="mt-n5"> {{ report[0].report }} </v-card-subtitle>
          </div>
          <div>
            <v-card
              v-for="(link, i) in report[0].proof.split(' ')"
              :key="i"
              outlined
              @click="openpage(link)"
              class="mx-3 mb-3"
              ripple
            >
              <v-card-title class="my-n2"> {{ link }}</v-card-title></v-card
            >
          </div>
          <v-expand-transition>
            <div class="mx-3">
              <v-text-field
                filled
                hide-details="auto"
                label="Username"
                v-model="report[0].username"
              >
              </v-text-field>
              <v-text-field
                filled
                class="rounded-0"
                hide-details="auto"
                label="Proof"
                v-model="report[0].proof"
              >
              </v-text-field>
              <v-text-field
                filled
                class="rounded-0"
                hide-details="auto"
                label="Note to reporter"
                v-model="report[0].note"
              >
              </v-text-field>
              <v-text-field
                filled
                class="rounded-0"
                hide-details="auto"
                label="Time"
                v-model="report[0].time"
              >
              </v-text-field>
              <v-textarea
                rows="3"
                class="rounded-0"
                filled
                label="Reason"
                v-model="report[0].report"
              >
              </v-textarea>
            </div>
          </v-expand-transition>
          <v-divider> </v-divider>
          <v-layout wrap class="py-2">
            <v-btn text @click="del(report[0])" color="red"> Deny </v-btn>
            <v-btn text color="green" @click="acc(report[0])"> Accept (Custom) </v-btn>
            <v-btn text color="green" @click="accn(report[0])"> Accept (No ban) </v-btn>
            <v-btn text color="green" @click="warn(report[0])"> Accept (Warn) </v-btn>
          </v-layout>
        </v-card>
      </div>
    </v-container>

    <v-container v-if="img.open" flex class="mx-auto" style="width: 100%">
      <v-overlay>
        <v-dialog v-model="img.open" max-width="1000px" max-height="1000px">
          <v-card class="mx-auto">
            <v-img :src="img.src">
              <v-card-actions>
                <v-spacer></v-spacer
                ><v-btn color="red" bottom plain @click="img.open = false"> Close </v-btn>
              </v-card-actions></v-img
            >
          </v-card>
        </v-dialog>
      </v-overlay>
    </v-container>

    <v-snackbar v-model="toast.open">
      {{ toast.message }}

      <template v-slot:action="{ attrs }">
        <v-btn
          :color="toast.color"
          text
          v-bind="attrs"
          timeout="2000"
          @click="toast.open = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import _ from "lodash";
export default {
  name: "Home",
  data: () => ({
    expand: false,
    reports: {},
    img: {
      open: null,
      src: null,
    },
    toast: {
      message: "",
      color: "success",
      open: false,
    },
    loading: true,
    unban: {
      username: "",
      found: true,
      loading: false,
    },
  }),
  methods: {
    async del(report) {
      try {
        await this.$http.post("/areport/" + report.id + "/deny", {
          note: report.note,
        });
      } catch (e) {
        this.toast.message = "Something went wrong";
        this.toast.color = "error";
        this.toast.open = true;
        return;
      }
      this.toast.message = "Sucessfully denied report";
      this.toast.color = "success";
      this.toast.open = true;
      delete this.reports[report.target];
    },
    async acc(report, time) {
      time = time ?? report.time;
      if (!time) {
        this.toast.message = "Please select a time";
        this.toast.color = "error";
        this.toast.open = true;
        return;
      }
      try {
        await this.$http.put("/areport/" + report.id, {
          note: report.note,
          reason: report.report,
          target: report.username,
          proof: report.proof,
          date: time,
        });
      } catch (e) {
        this.toast.message = "Something went wrong";
        this.toast.color = "error";
        this.toast.open = true;
        return;
      }
      this.toast.message = "Sucessfully accepted  report";
      this.toast.color = "success";
      this.toast.open = true;
      delete this.reports[report.target];
    },
    async accn(report) {
      try {
        await this.$http.put("/areport/" + report.id, {
          note: report.note,
          noban: true,
        });
      } catch (e) {
        this.toast.message = "Something went wrong";
        this.toast.color = "error";
        this.toast.open = true;
        return;
      }
      this.toast.message = "Sucessfully accepted report";
      this.toast.color = "success";
      this.toast.open = true;
      delete this.reports[report.target];
    },
    async warn(report) {
      try {
        await this.$http.post("/game/warn", {
          target: report.username,
          reason: report.report,
          proof: report.proof,
        });

        await this.$http.put("/areport/" + report.id, {
          note: report.note,
          noban: true,
        });
      } catch (e) {
        this.toast.message = "Something went wrong";
        this.toast.color = "error";
        this.toast.open = true;
        return;
      }
      this.toast.message = "Sucessfully accepted report";
      this.toast.color = "success";
      this.toast.open = true;
      delete this.reports[report.target];
      
    },
    async accb(report) {
      let time = report[0].time;
      if (!time) {
        this.toast.message = "Please select a time";
        this.toast.color = "error";
        this.toast.open = true;
        return;
      }

      try {
        await this.$http.put("/areport/" + report[0].id, {
          note: report[0].note,
          reason: report[0].report,
          target: report[0].username,
          proof: report[0].proof,
          date: time,
        });
      } catch (e) {
        this.toast.message = "Something went wrong";
        this.toast.color = "error";
        this.toast.open = true;
        return;
      }

      try {
        for (const r of report) {
          await this.$http.put("/areport/" + r.id, {
            note: report[0].note,
            noban: true,
          });
        }
      } catch (e) {
        this.toast.message =
          "Something went wrong well accepting other reports, the ban has been created though";
        this.toast.color = "error";
        this.toast.open = true;
        return;
      }

      this.toast.message = "Sucessfully accepted report";
      this.toast.color = "success";
      this.toast.open = true;
      delete this.reports[report[0].target];
    },
    async accw(report) {
      try {
        await this.$http.post("/game/warn", {
          target: report[0].username,
          reason: report[0].report,
          proof: report[0].proof,
        });
      } catch (e) {
        this.toast.message = "Something went wrong";
        this.toast.color = "error";
        this.toast.open = true;
        return;
      }

      try {
        for (const r of report) {
          await this.$http.put("/areport/" + r.id, {
            note: report[0].note,
            noban: true,
          });
        }
      } catch (e) {
        this.toast.message =
          "Something went wrong well accepting other reports, the ban has been created though";
        this.toast.color = "error";
        this.toast.open = true;
        return;
      }

      this.toast.message = "Sucessfully accepted report";
      this.toast.color = "success";
      this.toast.open = true;
      delete this.reports[report[0].target];

    }, async accnb(report) {
      try {
        for (const r of report) {
          await this.$http.put("/areport/" + r.id, {
            note: report[0].note,
            noban: true,
          });
        }
      } catch (e) {
        this.toast.message = "Something went wrong well accepting some reports";
        this.toast.color = "error";
        this.toast.open = true;
        return;
      }

      this.toast.message = "Sucessfully accepted all reports";
      this.toast.color = "success";
      this.toast.open = true;
      delete this.reports[report[0].target];
    },
    async denb(report) {
      try {
        for (const r of report) {
          await this.$http.post("/areport/" + r.id + "/deny", {
            note: report[0].note,
          });
        }
      } catch (e) {
        this.toast.message = "Something went wrong well denying some reports";
        this.toast.color = "error";
        this.toast.open = true;
        return;
      }

      this.toast.message = "Sucessfully denied all reports";
      this.toast.color = "success";
      this.toast.open = true;
      delete this.reports[report[0].target];
    },
    images(ban) {
      return ban.proof.split(" ").filter((i) => this.$isimage(i));
    },
    open: function (src) {
      this.img.src = src;
      this.img.open = true;
    },
    openpage: function (url) {
      window.open(url);
    },
  },
  async created() {
    let req = await this.$http.get("/areport/reports");
    console.log(req.data);
    this.reports = _.groupBy(
      req.data.map((c) => {
        c.expand = false;
        return c;
      }),
      "target"
    );
    this.loading = false;
  },
};
</script>
