<template>
  <div class="">
    <v-sheet color="red darken-2" height="70" style="width: 100%">
      <v-container>
        <v-layout class="mt-1">
          <div class="white--text">
            <p class="text-h4 font-weight-bold">Server {{ server.id }}</p>
          </div>
        </v-layout>
      </v-container>
    </v-sheet>
    <v-container>
      <v-row>
        <v-col>
          <v-card outlined>
            <v-card-text> Status </v-card-text>
            <v-row class="my-0 mx-4" v-if="server.status === 'online'">
              <div class="ring-container py-auto my-3 ml-0 mr-2">
                <div class="ringring"></div>
                <div class="circle"></div>
              </div>
              <p class="text-h3 font-weight-bold green--text">Online</p>
            </v-row>
            <v-row class="py-1 mx-4 flex" v-else>
              <div class="ring-container py-auto my-3 ml-0 mr-2">
                <div class="ringring-red"></div>
                <div class="circle-red"></div>
              </div>
              <p class="text-h3 font-weight-bold red--text">Offline</p>
            </v-row>
          </v-card>
        </v-col>
        <v-col>
          <v-card outlined>
            <v-card-text>
              {{ server.status === "online" ? "Uptime" : "downtime" }}
            </v-card-text>
            <v-row class="my-0 mx-4" v-if="server.status === 'online'">
              <p class="text-h3 font-weight-bold green--text">
                {{ server.minsSinceFirstPing }} Minutes
              </p>
            </v-row>
            <v-row class="py-1 mx-4 flex" v-else>
              <p class="text-h3 font-weight-bold red--text">
                {{ server.minsSincePing }} Minutes
              </p>
            </v-row>
          </v-card>
        </v-col>
        <v-col>
          <v-card outlined>
            <v-card-text>
              {{
                server.status === "online"
                  ? "Memory "
                  : "Memory before downtime"
              }}
            </v-card-text>
            <v-row class="my-0 mx-4" v-if="server.status === 'online'">
              <p class="text-h3 font-weight-bold green--text">
                {{ server.memory }}mb
              </p>
            </v-row>
            <v-row class="py-1 mx-4 flex" v-else>
              <p class="text-h3 font-weight-bold red--text">
                {{ server.memory }}mb
              </p>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <div id="chart">
        <apexChart
          height="350"
          :options="chartOptions"
          ref="chart"
          :series="server.series"
        ></apexChart>
      </div>
    </v-container>
  </div>
</template>

<script>
import pusher from "pusher-js";
import apexChart from "vue-apexcharts";

export default {
  name: "Home",
  data: () => ({
    clicks: 0,
    data: [],
    users: {},
    server: {},
    selectedPoint: {},
    chartOptions: {
      chart: {
        height: 380,
        width: "100%",
        type: "line",
        animations: {
          initialAnimation: {
            enabled: false,
          },
        },
        stroke: {
          curve: "straight",
        },
      },
      xaxis: {
        type: "datetime",
      },
    },
    owner: "",
  }),
  components: {
    apexChart,
  },

  mounted() {
    this.$http.get(`/game/servers`).then((res) => {
      let server = res.data.servers.find(
        (server) => server.id == this.$route.params.id
      );

      const lastPing = server.ping[server.ping.length - 1];
      const memory = lastPing.memory;
      const firstPing = server.ping[0];
      const minsSincePing = Math.floor(
        (Date.now() - new Date(lastPing.timestamp)) / 1000 / 60
      );
      const minsSinceFirstPing = Math.floor(
        (Date.now() - new Date(firstPing.timestamp)) / 1000 / 60
      );
      const series = [
        {
          name: "Memory",
          data: server.ping.map((ping) => {
            return [new Date(ping.timestamp).getTime(), ping.memory];
          }),
        },
        {
          name: "Players",
          data: server.ping.map((ping) => {
            return [new Date(ping.timestamp).getTime(), ping.players];
          }),
        },
      ];
      this.server = {
        ...server,
        memory,
        series,
        minsSincePing,
        minsSinceFirstPing,
      };
    });

    setInterval(() => {
      console.log("updating");
      const server = this.servers.find(
        (server) => server.id == this.$route.params.id
      );
      const lastPing = server.ping[server.ping.length - 1];
      const memory = lastPing.memory;
      const firstPing = server.ping[0];
      const minsSincePing = Math.floor(
        (Date.now() - new Date(lastPing.timestamp)) / 1000 / 60
      );
      const minsSinceFirstPing = Math.floor(
        (Date.now() - new Date(firstPing.timestamp)) / 1000 / 60
      );
      const series = [
        {
          name: "Memory",
          data: server.ping.map((ping) => {
            return [new Date(ping.timestamp).getTime(), ping.memory];
          }),
        },
        {
          name: "Players",
          data: server.ping.map((ping) => {
            return [new Date(ping.timestamp).getTime(), ping.players];
          }),
        },
      ];
      this.$refs.chart.updateSeries(
        series,
        false,
        true
      );
      this.server = {
        ...server,
        minsSincePing,
        memory,
        minsSinceFirstPing,
      };
    }, 1000 * 30);

    pusher.logToConsole = true;
    const pclient = new pusher("657f8c412185aa5cb845", {
      cluster: "us3",
    });

    const channel = pclient.subscribe("servers");
    channel.bind("update", (data) => {
      const server = data.servers.find(
        (server) => server.id == this.$route.params.id
      );
      const lastPing = server.ping[server.ping.length - 1];
      const firstPing = server.ping[0];
      const memory = lastPing.memory;
      const minsSincePing = Math.floor(
        (Date.now() - new Date(lastPing.timestamp)) / 1000 / 60
      );
      const minsSinceFirstPing = Math.floor(
        (Date.now() - new Date(firstPing.timestamp)) / 1000 / 60
      );
      const series = [
        {
          name: "Memory",
          data: server.ping.map((ping) => {
            return [new Date(ping.timestamp).getTime(), ping.memory];
          }),
        },
        {
          name: "Players",
          data: server.ping.map((ping) => {
            return [new Date(ping.timestamp).getTime(), ping.players];
          }),
        },
      ];
      this.$refs.chart.updateSeries(
        series,
        false,
        true
      );
      this.server = {
        ...server,
        minsSincePing,
        memory,
        minsSinceFirstPing,
      };
    });
  },
  methods: {},
};
</script>

<style>
.ring-container {
  position: relative;
  width: 25px;
  height: 25px;
  margin: 0 auto;
}

.circle {
  width: 15px;
  height: 15px;
  background-color: #62bd19;
  border-radius: 50%;
  top: 5px;
  left: 5px;
  position: absolute;
}

.ringring {
  border: 3px solid #62bd19;
  -webkit-border-radius: 30px;
  height: 25px;
  width: 25px;
  position: absolute;
  -webkit-animation: pulsate 1s ease-out;
  -webkit-animation-iteration-count: infinite;
  opacity: 0;
}

.circle-red {
  width: 15px;
  height: 15px;
  background-color: #fa0202;
  border-radius: 50%;
  top: 5px;
  left: 5px;
  position: absolute;
}

.ringring-red {
  border: 3px solid #fa0202;
  -webkit-border-radius: 30px;
  height: 25px;
  width: 25px;
  position: absolute;
  -webkit-animation: pulsate 1s ease-out;
  -webkit-animation-iteration-count: infinite;
  opacity: 0;
}
@-webkit-keyframes pulsate {
  0% {
    -webkit-transform: scale(0.1, 0.1);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1.2, 1.2);
    opacity: 0;
  }
}
</style>
